import React, { useState, useContext, useEffect,useRef } from "react";
import { Link, useSearchParams, useParams, useLocation } from "react-router-dom";
import SessionContext from "./Context/SessionContext";
import httpService from "./services/httpService";
import { apiUrl, PortalID, ContentMenuCode } from "./Config/Config.json";
// import sm from 'sitemap';
import fs from 'fs';
//const fs = require('fs');

function NewSiteMap(props) {
    var params = useParams();
  const context = useContext(SessionContext);

  const [menu, setMenu] = useState();

  const [flatMenu, setFlatMenu] = useState([]);
  const [siteMapXML, setSiteMapXML] = useState();

  const stateFile = "./public/sitemap.xml";

  function flattenMenu(menu) {

    //console.log("Flattening menu", menu);
    const flatMenuLst = [];
  
    function flatten(items) {
      for (const item of items) {
        if(item.url != null)
            {
                if(item.url.indexOf("http") == -1){
                    flatMenuLst.push({
                        id: item.id,
                        url: 
                        "https://www.alriyadh.gov.sa/ar" + item.url + (item.articleId != null ? "/" + item.articleId : "")
                      });
                      flatMenuLst.push({
                        id: item.id,
                        url: "https://www.alriyadh.gov.sa/en" + item.url + (item.articleId != null ? "/" + item.articleId : "")
                      });
                }
                else{
        flatMenuLst.push({
          id: item.id,
          url:item.url
        });
    }
    }
        if (item.subMenus && item.subMenus.length > 0) {
          flatten(item.subMenus);
        }
      }
    }
  
    flatten(menu);
    //console.log("flatMenuLst",flatMenuLst);
    generateSitemapXml(flatMenuLst);
    return flatMenuLst;
  }


  const generateSitemapXml = (menu) => {
    let xml = `<?xml version="1.0" encoding="UTF-8"?>\n`;
    xml += `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">\n`;
  
    menu.forEach(item => {
      xml += `  <url>\n`;
      xml += `    <loc>${item.url}</loc>\n`;
      xml += `    <lastmod>${new Date()}</lastmod>\n`;
      xml += `  </url>\n`;
    });
  
    xml += `</urlset>`;
    //fs.writeFileSync('./public/sitemap.xml', xml.toString());

    //const saveLastEventSequenceId = (sequenceId) => {
      try {
        fs.writeFileSync(stateFile, xml.toString());
      } catch (err) {
        throw err;
      }
    //};
    //console.log("xml",xml);
    setSiteMapXML(xml);
    return xml;
  };

  useEffect(() => {
    async function getMenu() {
      //debugger;

      const result = await httpService
        .post(
          apiUrl + "/Menus/GetSiteMap",

          { ReferenceID: PortalID },
          { tokenVal: context.tokenVal }
        )
        .then((res) => {

          setMenu(res.data.output.MenusEntity.subMenus);
          const newflatMenu = flattenMenu(res.data.output.MenusEntity.subMenus);
      setFlatMenu(newflatMenu);

    //   const sitemap = sm.createSitemap({
    //     hostname: 'https://www.alriyadh.gov.sa',
    //     urls: newflatMenu,
    //});

    
    //fs.writeFileSync('build/public/sitemap.xml', sitemap.toString());

        });
    }
    if (context.tokenVal != undefined) {
      getMenu();
      
    }
    
  }, [context.tokenVal, false]);
  const location = useLocation();
 
  return (
    <React.Fragment>
 

      <section className="service-single rtl" id="service-single">
        <div className="container">

          <div className="row">
            <div class="col-12 col-lg-12 order-0">
             
             
                    {

siteMapXML?siteMapXML:<></>

                        // {
                        //       menu.map(menuItem =>
                        //         (
                               
                        //         ))
                        //     }


          


                    }
                   
                  </div>
                </div>
              </div>
      </section>
    </React.Fragment>
  );
}

export default NewSiteMap;
