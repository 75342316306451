import React, { useState, useContext, useEffect } from "react";

import { useParams, useSearchParams } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";

import httpService from "../../services/httpService";
import { apiUrl, PortalID ,newOpenDataGateWay} from "../../Config/Config.json";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import SubMenu from "../../Controls/SubMenu";
import TotalRequests from "./../EservicesStats/TotalRequests";
import Building from "./../EservicesStats/Building";
import Trading from "./../EservicesStats/Trading";
import Medical from "./../EservicesStats/Medical";
import SWP from "./../EservicesStats/SWP";
import useLocalizer from "../../Localization/useLocalizer";

function DataSetLanding(props) {
  const context = useContext(SessionContext);
  const [pageContent, setPageContent] = useState([]);
  const [listContent, setListContent] = useState([]);
  const [ManualListContent, setManualListContent] = useState([]);
  
  const localizer = useLocalizer();
  useEffect(() => {
    async function getListContent() {
      const result = await httpService
        .post(
          apiUrl +newOpenDataGateWay+ "/SuccessStories/GetAll",
          { ReferenceID: PortalID },
          { tokenVal: context.tokenVal }
        )
        .then((res) => {
          setListContent(res.data.data.items);
        });
    }

    async function getManualListContent() {
      const result = await httpService
        .post(
          apiUrl +newOpenDataGateWay+ "/ManualAndPolices/GetAll",
          {
            ReferenceID: PortalID,
          },
          { tokenVal: context.tokenVal }
        )
        .then((res) => {
          setManualListContent(res.data.data.items);
        });
    }
  
    if (context.tokenVal != undefined) {
      getListContent();
      getManualListContent();
    }
  }, [context.tokenVal]);

  return (
    <React.Fragment>
      {localizer.getLang()=="/ar"?
        <link
          rel="stylesheet"
          type="text/css"
          href="/assets/css/new_open_data.css"
        />
      :<link
          rel="stylesheet"
          type="text/css"
          href="/assets/css/new_open_dataEn.css"
        />
      }
      <section className="page-title page-title-6 center" id="page-title">
        <div
          className="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="title">
                  <h1 className="title-heading">
                    {localizer.getJsonKey("OpenData")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="breadcrumb-wrap rtl">
          <div className="container">
            <ol className="breadcrumb d-flex">
              <li className="breadcrumb-item">
                <a href={localizer.getLang() + "/"}>
                  {" "}
                  {localizer.getJsonKey("Home")}
                </a>
              </li>

              <li className="breadcrumb-item active" aria-current="page">
                {localizer.getJsonKey("OpenData")}
              </li>
            </ol>
          </div>
        </div>
      </section>

      <section className="service-single rtl" id="service-single">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 order-0">
              <div className="service-entry">
                <div className="entry-content">
                  <div className="entry-introduction entry-infos">
                    <h5 className="entry-heading">
                      {localizer.getJsonKey("OpenData")}
                    </h5>
                    {localizer.getLang()=="/ar"?
                    <>
                    <p className="entry-desc dataSetLandingTitle">
                      البيانات المفتوحة هي تلك البيانات التي يُمكن لأي فرد
                      استخدامها بحرية ودون قيود تقنية أو مالية أو قانونية،
                      وأيضًا إعادة استخدامها ونشرها مع مراعاة متطلبات الرخصة
                      القانونية التي تم نشر هذه البيانات بموجبها من خلال{" "}
                      <a
                        // href="https://od.data.gov.sa/ar/policies/open-data-license"
                        //href="https://open.data.gov.sa/en/home"
                        href="https://open.data.gov.sa/ar/publishers/4a9f48e7-b2c1-4d3c-9445-01212acb9bd8"
                        target="_blank"
                        rel="noopener"
                      >
                        <strong>البوابة الوطنية للبيانات المفتوحة ـ</strong>
                      </a>
                    </p>
                    <p className="entry-desc dataSetLandingTitle">
                     و قد وفرت أمانة الرياض البيانات المفتوحة لجميع الزوار من
                     خلال مكتبة مفتوحة تحوي على عدة أنواع من البيانات بصيغ
                     متعددة تسهل معالجتها وإعادة استخدامها، و ذلك لتعزيز
                     الشفافية وتشجيع المشاركة الإلكترونية. و سعت الأمانة إلى
                     مشاركة هذه البيانات مع المستفيدين 
                    </p>
                    <p className="entry-desc dataSetLandingTitle">
                      كما أتاحت الأمانة للمستفيد إمكانية الحصول على البيانات
                      بالصيغ المختلفة من خلال تقديم{" "}
                      <a
                      // href="https://alriyadh.gov.sa/ar/ContactUs/OpenDataRequest"
                      href={localizer.getLang()+'/support/OpenDataRequest'}
                      target="_blank"
                      rel="noopener"
                      >
                      <strong>طلب بيانات مفتوحة</strong>
                      </a>{" "}
                      ويتم الرد على هذه الطلبات خلال 27 أيام عمل من تاريخ تقديم
                      الطلب
                    </p>
                    </>
                    :
                    <>
                    <p className="entry-desc dataSetLandingTitle">
                      Open data is data that anyone can freely use without technical, financial or legal restrictions, and also able to re-use and publish it, taking into account the requirements of the legal license under which this data was published through 
                      <a
                        // href="https://od.data.gov.sa/en/policies/open-data-license"
                        href="https://open.data.gov.sa/en/home"
                        target="_blank"
                        rel="noopener"
                      >
                        <strong> National Open Data Portal</strong>
                      </a>  
                    </p>
                    <p className="entry-desc dataSetLandingTitle">
                      Riyadh Municipality has provided open data for all its visitors through an open library portal that contains several types of data in multiple 
                      formats which facilitate processing and reuse, in order to enhance transparency and encourage e-participation. Riyadh Municipality sought to share 
                      this data with the beneficiaries 
                    </p>
                    <p className="entry-desc dataSetLandingTitle">
                      Riyadh Municipality also made it possible for the beneficiary to obtain data in various formats by submitting an 
                      <a
                        // href="https://alriyadh.gov.sa/ar/ContactUs/OpenDataRequest"
                        href={localizer.getLang()+'/support/OpenDataRequest'}
                        target="_blank"
                        rel="noopener"
                      >
                        <strong> open data request</strong>
                      </a>{" "}
                      and these requests will be closed within 7 business days from the date of submitting the request
                    </p>
                    </>
                    }
                    <div
                      class="projects projects-standard projects-standard-1 rtl"
                      id="projects-standard-1">
                        <div class="row" id="projects-all">

                          <div class="col-12 col-md-6 col-lg-4 project-item filter-events filter-riyadh">
                            <div class="project-panel" data-hover="">
                              <div class="project-panel-holder">
                                <div class="project-content">
                                  <div class="project-cat land-icon-div">
                                      <a className="dataSetLandingTitle"
                                          href={localizer.getLang()+'/content/NG9PRDNHOWVBeE09'}
                                          // "https://mapservice.alriyadh.gov.sa/geoportal/geomap"
                                          target="_blank"
                                          rel="noopener">
                                          <i className="fas fa-book-open fa-3x" ></i>
                                      </a>
                                  </div>
                                  <div class="project-cat land-icon-div">
                                  <a className="dataSetLandingTitle"
                                      href={localizer.getLang()+'/content/NG9PRDNHOWVBeE09'}
                                      // "https://mapservice.alriyadh.gov.sa/geoportal/geomap"
                                      target="_blank"
                                      rel="noopener">
                                        {localizer.getJsonKey("GovOpenData")}
                                  </a>
                                  </div>
                                </div>
                                
                              </div>
                            </div>
                          </div>

                          

                          <div class="col-12 col-md-6 col-lg-4 project-item filter-events filter-riyadh">
                            <div class="project-panel" data-hover="">
                              <div class="project-panel-holder">
                                <div class="project-content">
                                  <div class="project-cat land-icon-div">
                                      <a className="dataSetLandingTitle"
                                          href={localizer.getLang()+'/DataSetTypesLanding'}
                                          target="_blank"
                                          rel="noopener">
                                          <i className="fas fa-database fa-3x" ></i>
                                      </a>
                                  </div>
                                  <div class="project-cat land-icon-div">
                                      <a className="dataSetLandingTitle"
                                          href={localizer.getLang()+'/DataSetTypesLanding'}
                                          target="_blank"
                                          rel="noopener">
                                            {localizer.getJsonKey("landingDataSets")}
                                      </a>
                                  </div>
                                </div>
                                
                              </div>
                            </div>
                          </div>

                          {/* <div class="col-12 col-md-6 col-lg-4 project-item filter-events filter-riyadh">
                            <div class="project-panel" data-hover="">
                              <div class="project-panel-holder">
                                <div class="project-content">
                                  <div class="project-cat land-icon-div">
                                      <a className="dataSetLandingTitle"
                                          href={localizer.getLang()+'/support/OpenDataRequest'}
                                          target="_blank"
                                          rel="noopener">
                                          <i className="fa fa-paper-plane fa-3x" ></i>
                                      </a>
                                  </div>
                                  <div class="project-cat land-icon-div">
                                  <a className="dataSetLandingTitle"
                                      href={localizer.getLang()+'/support/OpenDataRequest'}
                                      target="_blank"
                                      rel="noopener">
                                        {localizer.getJsonKey("landingOpenDataRequest")}
                                  </a>
                                  </div>
                                </div>
                                
                              </div>
                            </div>
                          </div> */}

                          <div class="col-12 col-md-6 col-lg-4 project-item filter-events filter-riyadh">
                            <div class="project-panel" data-hover="">
                              <div class="project-panel-holder">
                                <div class="project-content">
                                  <div class="project-cat land-icon-div">
                                      <a className="dataSetLandingTitle"
                                          href={localizer.getLang()+'/content/K2RKNGpINitnSkU9'}
                                          // "https://mapservice.alriyadh.gov.sa/geoportal/geomap"
                                          target="_blank"
                                          rel="noopener">
                                          <i className="fas fa-map-marker-alt fa-3x" ></i>
                                      </a>
                                  </div>
                                  <div class="project-cat land-icon-div">
                                  <a className="dataSetLandingTitle"
                                      href={localizer.getLang()+'/content/K2RKNGpINitnSkU9'}
                                      // "https://mapservice.alriyadh.gov.sa/geoportal/geomap"
                                      target="_blank"
                                      rel="noopener">
                                        {localizer.getJsonKey("landingDataSetsGeoLocation")}
                                  </a>
                                  </div>
                                </div>
                                
                              </div>
                            </div>
                          </div>

                          {/* <div class="col-12 col-md-6 col-lg-4 project-item filter-events filter-riyadh">
                            <div class="project-panel" data-hover="">
                              <div class="project-panel-holder">
                                <div class="project-content">
                                  <div class="project-cat land-icon-div">
                                      <a className="dataSetLandingTitle"
                                          href={localizer.getLang()+'/DataSetPolices'}
                                          target="_blank"
                                          rel="noopener">
                                          <i className="fa fa-landmark fa-3x" ></i>
                                      </a>
                                  </div>
                                  <div class="project-cat land-icon-div">
                                  <a className="dataSetLandingTitle"
                                      href={localizer.getLang()+'/DataSetPolices'}
                                      target="_blank"
                                      rel="noopener">
                                        {localizer.getJsonKey("landingPolices")}
                                  </a>
                                  </div>
                                </div>
                                
                              </div>
                            </div>
                          </div> */}

                          {/* <div class="col-12 col-md-6 col-lg-4 project-item filter-events filter-riyadh">
                            <div class="project-panel" data-hover="">
                              <div class="project-panel-holder">
                                <div class="project-content">
                                  <div class="project-cat land-icon-div">
                                      <a className="dataSetLandingTitle"
                                          href={localizer.getLang()+'/DataSetStat'}
                                          target="_blank"
                                          rel="noopener">
                                          <i className="fas fa-chart-bar fa-3x" ></i>
                                      </a>
                                  </div>
                                  <div class="project-cat land-icon-div">
                                  <a className="dataSetLandingTitle"
                                      href={localizer.getLang()+'/DataSetStat'}
                                      target="_blank"
                                      rel="noopener">
                                        {localizer.getJsonKey("landingOpenDataStatistics")}
                                  </a>
                                  </div>
                                </div>
                                
                              </div>
                            </div>
                          </div> */}
                         {ManualListContent.length >0 &&
                          <div class="col-12 col-md-6 col-lg-4 project-item filter-events filter-riyadh">
                            <div class="project-panel" data-hover="">
                              <div class="project-panel-holder">
                                <div class="project-content">
                                  <div class="project-cat land-icon-div">
                                      <a className="dataSetLandingTitle"
                                          href={localizer.getLang()+'/DataSetUserManuals'}
                                          target="_blank"
                                          rel="noopener">
                                          <i className="fa fa-file fa-3x" ></i>
                                      </a>
                                  </div>
                                  <div class="project-cat land-icon-div">
                                  <a className="dataSetLandingTitle"
                                      href={localizer.getLang()+'/DataSetUserManuals'}
                                      target="_blank"
                                      rel="noopener">
                                        {localizer.getJsonKey("landingUserManuals")}
                                  </a>
                                  </div>
                                </div>
                                
                              </div>
                            </div>
                          </div>
                        }
                          <div class="col-12 col-md-6 col-lg-4 project-item filter-events filter-riyadh">
                            <div class="project-panel" data-hover="">
                              <div class="project-panel-holder">
                                <div class="project-content">
                                  <div class="project-cat land-icon-div">
                                      <a className="dataSetLandingTitle"
                                          href={localizer.getLang()+'/support/CommissionerAdditionRequest'}
                                          target="_blank"
                                          rel="noopener">
                                          <i className="fa fa-paper-plane fa-3x" ></i>
                                      </a>
                                  </div>
                                  <div class="project-cat land-icon-div">
                                  <a className="dataSetLandingTitle"
                                      href={localizer.getLang()+'/support/CommissionerAdditionRequest'}
                                      target="_blank"
                                      rel="noopener">
                                        {localizer.getJsonKey("AddCommissionerRequest")}
                                  </a>
                                  </div>
                                </div>
                                
                              </div>
                            </div>
                          </div>
                          {listContent.length > 0 &&
                          <div class="col-12 col-md-6 col-lg-4 project-item filter-events filter-riyadh">
                            <div class="project-panel" data-hover="">
                              <div class="project-panel-holder">
                                <div class="project-content">
                                  <div class="project-cat land-icon-div">
                                      <a className="dataSetLandingTitle"
                                          href={localizer.getLang()+'/DataSetSuccessStories'}
                                          target="_blank"
                                          rel="noopener">
                                          <i className="fa fa-book fa-3x" ></i>
                                      </a>
                                  </div>
                             
                                    <div class="project-cat land-icon-div">
                                    <a className="dataSetLandingTitle"
                                        href={localizer.getLang()+'/DataSetSuccessStories'}
                                        target="_blank"
                                        rel="noopener">
                                          {localizer.getJsonKey("DataSetSuccessStories")}
                                    </a>
                                    </div>
                                  
                                 </div>
                              </div>
                            </div>
                          </div>
                          }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <SubMenu />
          </div>
        </div>
      </section>
      
    </React.Fragment>
  );
}

export default DataSetLanding;
